import React, {useEffect} from "react";
import * as firebaseui from "firebaseui";
import firebase from "firebase";
import "./style.scss";
import Logo from "../Logo";

export default function Login() {
  useEffect(()=>{
    console.log(firebaseui);
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#login', {
      signInOptions: [
        //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
    });
  },[]);

  return <div className={'login-container'}>
    <h4>
      <span className={'reboot'}>#rebootaviation</span>
      <br/>
      <br/>
      Welcome to #rebootviation news!<br/>
    </h4>
    <div id={'login'}/>
  </div>
}
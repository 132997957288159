import React,{Component} from 'react';
import './style.scss';
import firebase from "firebase";
import classnames from 'classnames';
import Logo from "../Logo"

export default class App extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: firebase.auth().currentUser.email,
      showAll: true
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      await firebase.firestore().collection('usernews').doc(this.state.email).get();
    }catch (e) {
      if(e.code === 'permission-denied'){
        const msg = `Your email ${this.state.email} is not authorized to view the news. Please drop an email to nk@assaia.com to request the access. Thanks!`
        this.setState({error:msg});
      }else {
        this.setState({error:'Error occurred. Please drop an email to nk@assaia.com.'});
      }
      return;
    }


    let usernews = await firebase.firestore().collection('usernews').doc(this.state.email).get();
    if(!usernews.exists){
      usernews = await firebase.firestore().collection('usernews').doc('newuser@assaia.com').get();
    }

    usernews = usernews.data().news;
    const maxPoolSize = 10;
    let items = [];

    const fn = async () => {
      let id = usernews.pop();
      console.log(id);
      if(!id)
        return;

      let item = await firebase.firestore().collection('news').doc(id).get();
      item = {id:item.id,...item.data()};
      if(!item.upvoted_by)
        item.upvoted_by = [];
      if(!item.downvoted_by)
        item.downvoted_by = [];
      if(!item.clicked_by)
        item.clicked_by = [];
      items = [...items,item];
      this.setState({items});
      fn();
    };

    for(let i = 0; i<maxPoolSize; i++) {
      fn();
    }
  }

  upvote(item) {
    const {email} = this.state;
    this.updateItem(item,{
      upvoted_by:firebase.firestore.FieldValue.arrayUnion(email),
      downvoted_by:firebase.firestore.FieldValue.arrayRemove(email)
    });
  }

  downvote(item) {
    const {email} = this.state;
    this.updateItem(item,{
      upvoted_by:firebase.firestore.FieldValue.arrayRemove(email),
      downvoted_by:firebase.firestore.FieldValue.arrayUnion(email)
    });
  }

  async onArticleLinkClick(item) {
    console.log("on link click",item);
    const {email} = this.state;
    this.updateItem(item,{
      clicked_by:firebase.firestore.FieldValue.arrayUnion(email),
    });
  }

  async updateItem(item, data) {
    await firebase.firestore().collection('news').doc(item.id).update(data);
    data = await firebase.firestore().collection('news').doc(item.id).get();
    data = data.data();
    let items = [...this.state.items];
    let index = items.indexOf(item);
    items[index] = {
      ...item,
      ...data
    };
    this.setState({items});
  }

  renderItems() {
    let {items,email} = this.state;

    // if(!showAll)
    //   items = items.filter(i=>usernews.includes(i.id));

    return items.map((item,index)=>{
      let upvoted = item.upvoted_by.includes(email);
      let downvoted = item.downvoted_by.includes(email);


      return <div key={item.id} className={'border rounded p-2 mb-2 d-flex'}>
            <div className={'mr-3 text-center votes'}>
              <a
                className={classnames('d-block',{active:upvoted})}
                onClick={()=>!upvoted && this.upvote(item)}
              >
                <i className={'fas fa-caret-up'}/>
              </a>
              <a
                className={classnames("d-block",{active:downvoted})}
                onClick={()=>!downvoted && this.downvote(item)}
              >
                <i className={'fas fa-caret-down'}/>
              </a>
            </div>
            <div style={{flex:1}}>
              <a className="card-title" href={item.link_fullarticle} target={'_blank'} onClick={()=>this.onArticleLinkClick(item)}>
                <h4>
                  {item.headline}
                  &nbsp;
                </h4>
                <p className="card-text">
                  {item.teaser}
                  &nbsp;
                  <i className="fas fa-external-link-alt"></i>
                </p>
              </a>
            </div>
      </div>
    })
  }

  render() {
    const {items,showAll,error} = this.state;


    return (
      <div className={'container root'}>
        <header>
          <span className={'reboot'}>#rebootaviation</span>
          {/*<a href={'https://assaia.com'}><Logo/></a>*/}
        </header>
        {!error && <div className={'news'}>
          {items ? this.renderItems() : 'Loading...'}
        </div>}
        {error && <h4 className={'error'}>{error}</h4>}
      </div>
    )
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as Sentry from "@sentry/browser";
import firebase from "firebase";
import './firebase';
import Login from "./components/Login";

Sentry.init({
  dsn: 'https://e3ba447c670345489e334a83ac8152cd@sentry.assaia.com/4'
});
Sentry.captureMessage("Application start.");

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    ReactDOM.render(
      <React.StrictMode>
        <App user={user}/>
      </React.StrictMode>,
      document.getElementById('root')
    );
  } else {
    ReactDOM.render(
      <Login/>,
      document.getElementById('root')
    )
  }
});